<script>
import {formatCurrency} from "../../helpers";

export default {
  name: "Donors",
  methods: {formatCurrency},
  props: {
    donats: {
      type: Array,
      required: true
    }
  }
}
</script>

<template>
  <div class="contents">
    <div class="flex flex-row justify-center w-full text-lg mb-2 uppercase mt-8">
      <div class="text-sm ">Donors, останні 500</div>
    </div>
    <div class="jar bg-slate-200 border rounded-xl shadow  p-2">
      <div class="w-full flex-col flex text-sm space-y-1">

        <TransitionGroup name="list" tag="div" class="mb-2">
          <div class="flex bg-white p-4 rounded-xl shadow mb-1 text-xs grid gap-2 grid-cols-12" v-for="donat in donats"
               :key="donat.id">
            <div class="col-span-12 md:col-span-10 flex flex-col">
              <p v-if="donat.published_at" class="text-xs text-green-500">Опубліковано</p>
              <p v-if="!donat.published_at" class="text-xs text-gray-500">В черзі на публикацію</p>
              <p class="text-sm">
                <span v-if="donat.is_spam">**** SPAM ****</span>
                <span v-else>
                {{ donat.comment }}
              </span>
              </p>
            </div>
            <div class="col-span-12 text-right md:col-span-2">
              {{ donat.amount ? formatCurrency(donat.amount / 100) : '--' }}
            </div>
          </div>


        </TransitionGroup>

      </div>
    </div>
  </div>
</template>

<style lang="scss">
.list-enter-active,
.list-leave-active {
  transition: all 3s ease;
}
.list-enter-from,
.list-leave-to {
  opacity: 0;
  transform: translateX(130px);
}
</style>