<script>
import {formatCurrency} from "@/helpers";

export default {
  name: "Top3",
  methods: {formatCurrency},
  props: {
    donats: {
      type: Array,
      required: true
    }
  }
}
</script>

<template>
  <div class="contents">
    <div class="flex flex-row justify-center w-full text-lg mb-2 uppercase mt-8">
      <div class="text-sm ">Top 3</div>
    </div>
    <div class="jar border-slate-500 bg-slate-200 border rounded-xl shadow  p-4">
      <div class="w-full flex-col flex text-sm space-y-4">
        <div class="flex bg-white p-4 rounded-xl shadow  text-xl font-bold grid gap-2 grid-cols-12"
             v-if="donats && donats[0]">
          <div class="col-span-3 md:col-span-1">1</div>
          <div class="col-span-12 md:col-span-8">{{ donats[0].comment }}</div>
          <div class="col-span-2 md:col-span-3 text-right justify-end">
            {{ (donats && donats[0] && donats[0].amount) ? formatCurrency(donats[0].amount / 100) : '--' }}
          </div>
        </div>
        <div class="flex bg-white p-4 rounded-xl shadow  text font-bold grid gap-2 grid-cols-12"
             v-if="donats && donats[1]">
          <div class="col-span-3 md:col-span-1">2</div>
          <div class="col-span-12 md:col-span-8">{{ donats[1].comment }}</div>
          <div class="col-span-2 md:col-span-3 text-right">
            {{ (donats && donats[1] && donats[1].amount) ? formatCurrency(donats[1].amount / 100) : '--' }}
          </div>
        </div>
        <div class="flex bg-white p-4 rounded-xl shadow  text font-bold grid gap-2 grid-cols-12"
             v-if="donats && donats[2]">
          <div class="col-span-3 md:col-span-1">3</div>
          <div class="col-span-12 md:col-span-8">{{ donats[2].comment }}</div>
          <div class="col-span-2 md:col-span-3 text-right">
            {{ (donats && donats[2] && donats[2].amount) ? formatCurrency(donats[2].amount / 100) : '--' }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">

</style>