<script>
export default {
  name: "InfoBlocks"
}
</script>

<template>
  <div class="grid gap-2 grid-cols-1 md:grid-cols-2">
    <div class="jar bg-yellow-400 rounded-xl shadow  p-4">
      <div class="flex flex-row w-full text-sm uppercase">
        <div class="text-lg md:text-4xl font-light">Скануй</div>
        <div class="text-lg md:text-4xl font-bold">Пиши!</div>
      </div>
      <div class="w-full mt-4 flex-col flex text-">
        <div class="flex text-xs md:text-sm">
          <p>Отскануй QR код банки збору, <span class="font-bold">залиш коментар</span> при переказі і він буде опублікован в "Прямий Эфір". Пиши, що хочешь.</p>
        </div>
      </div>
    </div>
    <div class="jar bg-rose-200 rounded-xl shadow  p-4">
      <div class="flex flex-row w-full text-sm uppercase">
        <div class="text-lg md:text-4xl font-light uppercase">безпечно!</div>
      </div>
      <div class="w-full mt-4 flex-col flex text-">
        <div class="flex flex-col text-xs md:text-sm">
          <p>DonorDeck <b>не торкається коштів</b>!</p>
          <p>Всі збори їдуть дочірні банки, це необхідно для роботи сайту, доступ до коштів має тільки автор цільової банки</p>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">

</style>