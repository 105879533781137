<script>
import {formatCurrency} from "../../helpers";

export default {
  name: "LastMessage",
  methods: {formatCurrency},
  props:{
    donat: {
      type: Object,
      required: true
    }
  }
}
</script>

<template>
  <div class="contents">
    <div class="flex flex-row justify-center w-full text-lg mb-2 uppercase mt-8">
      <div class="text-sm ">В ЭФІРІ</div>
    </div>
    <div class="bg-gray-50 rounded-xl  p-4 border-4 justify-center border-dashed items-center flex w-full flex-col">
      <p class=" text-4xl font-bold text-center">{{donat && donat.comment ? donat.comment : 'Стань першим! Зроби донат і залиш коментар'}}</p>
      <p class="text-gray-500"> {{ donat && donat.amount ? formatCurrency(donat.amount/100) : '--'}}</p>
    </div>
  </div>
</template>

<style scoped lang="scss">

</style>