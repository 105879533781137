<script>
import LastMessage from "@/views/jar/LastMessage.vue";
import Top3 from "@/views/jar/Top3.vue";
import {formatCurrency} from "@/helpers";
import Donors from "@/views/jar/Donors.vue";
import InfoBlocks from "@/views/jar/InfoBlocks.vue";
import laravelEcho from "@core/libs/sokets";
import QrcodeVue, {QrcodeCanvas} from 'qrcode.vue'
import Modal from "@/views/Modal.vue";



export default {
  name: "JarView",
  components: {
    Modal,
    InfoBlocks, Donors, Top3, LastMessage, QrcodeVue,
    QrcodeCanvas,

  },
  data() {
    return {
      modal :null,
      lastSubscribeId: null,
      interval: null,
      jarPublicKey: null,
      donats: [],
      jar: {
        name: 'a',
        html: '1',
        id: '123',
        public_key: null,
        amount_current: 123100,
        amount_required: 5000000
      }
    }
  },
  computed: {
    currentUrl(){
      return top.location.href
    },
    amountRequired() {
      return formatCurrency(this.jar.amount_required / 100)
    },
    amountCurrent() {
      return formatCurrency(this.jar.amount_current / 100)
    }
  },
  beforeDestroy() {
    this.disconnect()
  },
  created() {
    this.setJarPublicKey();
  },
  methods: {
    setJarPublicKey() {
      this.jarPublicKey = this.$route.params.jar;
      this.loadJar()
    },
    formatCurrency,
    reloadJar() {
      this.$http.get(`/api/jar/${this.jarPublicKey}`).then(({data: resp}) => {
        this.jar = resp.data
      })
    },
    loadJar() {
      this.$http.get(`/api/jar/${this.jarPublicKey}`).then(({data: resp}) => {
        this.jar = resp.data
        this.loadDonats(1)
        this.subscribe();
      })
    },
    disconnect() {
      if (this.interval) {
        clearInterval(this.interval)
      }
      if (this.lastSubscribeId) {
        laravelEcho.leave('jar.' + this.lastSubscribeId)
      }
    },
    loadDonats(page) {
      if (page === 1) {
        this.donats = []
      }
      this.$http.get(`/api/jar/${this.jar.public_key}/donats`).then(({data: resp}) => {
        if (page === 1) {
          this.donats = resp.data
        } else {
          this.donats.push(...resp.data)
        }
      })
    },
    subscribe() {
      this.disconnect()

      this.interval = setInterval(this.reloadJar, 5 * 60 * 1000);

      this.lastSubscribeId = this.jarPublicKey

      laravelEcho.listen('jar.' + this.jarPublicKey, 'NewDonatCreated', ({donat}) => {
        this.jar.amount_current += donat.amount
        this.donats = [donat, ...this.donats]
      })
      laravelEcho.listen('jar.' + this.jarPublicKey, 'PublishMessage', ({donat}) => {
        this.jar.last_donat = donat
        const d = this.donats.find( d => d.id === donat.id)
        if(d){
          d.published_at = donat.published_at;
        }
      })
    },
  }
}
</script>

<template>
  <div class="contents" v-if="jar.public_key">
    <!-- greets -->
    <InfoBlocks/>

    <!-- current jar -->
    <div class="flex flex-row justify-between w-full text-lg mb-2 uppercase mt-8">
      <div class="text-xs ">Поточний збір</div>
      <div class="text-xs">
        <router-link :to="{name: 'home'}" class="underline">Інші банки &rarr;</router-link>
      </div>
    </div>

    <div class="bg-green-400 shadow rounded-xl p-4 items-center text-4xl font-bold text-center" v-if="jar.is_done">
      <p>Дякуємо! Збір закрито! </p>
    </div>

    <Modal v-if="modal === 'share'" @close="modal = null">
      <template #header>
        Поділитися збором
      </template>
      <template #body>
        <div class="grid gap-4 grid-cols-12 mt-4 mb-4">
          <div class="flex flex-col bg-white rounded p-4 justify-center items-center col-span-12 md:col-span-3">
            <p class="mb-4">QR на DonorDeck</p>
            <img :src="'https://api.qrserver.com/v1/create-qr-code/?size=150x150&data='+currentUrl" width="150"/>
          </div>
          <div class="col-span-12 md:col-span-9 flex flex-col">
            <div class="bg-white p-4 rounded font-bold">
              Посилання на сторінку: {{ currentUrl }}
            </div>
          </div>
        </div>
      </template>
    </Modal>

    <div class="jar bg-slate-200 rounded-xl shadow  p-4 mt-2">

      <div class="w-full space-x-2 grid grid-cols-12">
        <div class="col-span-3 sm:col-span-2 flex flex-col items-center">
          <a :href="'https://send.monobank.ua/jar/'+jar.monobank_id" target="_blank" class="text-center">
            <img :src="'https://api.qrserver.com/v1/create-qr-code/?size=150x150&data=https://send.monobank.ua/jar/'+jar.monobank_id"/>
            <p class="mt-2 text-xs underline">
              Посилання на банку</p>
          </a>
        </div>
        <div class="col-span-9 sm:col-span-10 h-full justify-between flex flex-col">

          <div class="flex-grow">
            <div class="flex flex-row justify-between">
              <p class="font-bold text-xl">{{ jar.name }}</p>
              <p class="font-bold text-sm underline cursor-pointer" @click="modal='share'">Поділитися збором</p>
            </div>
            <p v-html="jar.html" class="whitespace-nowrap">
            </p>
          </div>
          <div class="mt-4">
            <p class="text-sm mb-2">Зібрано <b>{{ amountCurrent }}</b> з {{ amountRequired }}</p>

            <div class="w-full bg-gray-500 dark:bg-gray-700">
              <div class="bg-green-500 text-xs font-medium text-white text-center p-0.5 leading-none"
                   :style="{width: (((jar.amount_current >= jar.amount_required) ? jar.amount_required : jar.amount_current) * 100 / jar.amount_required)+'%'}"
              > {{ ((((jar.amount_current >= jar.amount_required) ? jar.amount_required : jar.amount_current) * 100 / jar.amount_required)).toFixed(3) }}%
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- last -->
    <LastMessage :donat="jar.last_donat"/>
    <!-- hall -->
    <Top3 :donats="jar.top3"/>
    <!-- hall -->
    <Donors :donats="donats"/>
  </div>


</template>

<style>

</style>